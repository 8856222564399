// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";
// 2. Include any default variable overrides here
// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/reboot";
// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/card";

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

body,
html {
  font-family: "Roboto", sans-serif;
}
